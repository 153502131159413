import {
  CreateClassRequest,
  JoinClassRequest,
  GetClassRequest,
  GetClassResponse,
  SearchClassesRequest,
  SearchClassesResponse,
  UpdateClassRequest,
  ValidateJoinClassRequest,
  ValidateJoinClassResponse,
  OrderClassSeatRequest,
  OrderClassSeatResponse,
  CompleteClassSeatPurchaseRequest,
  ArchiveClassRequest,
  UnarchiveClassRequest,
  DropStudentsFromRosterRequest,
  RestoreStudentsToRosterRequest,
  TransferStudentsToClassRequest,
  StudentsCompletedAssignmentsRequest,
  StudentsCompletedAssignmentsResponse,
  GetClassAssignmentsRequest,
  CreateAssignmentRequest,
  ReplaceInstructorsRequest,
  GetClassInstructorsRequest,
  GetClassInstructorsResponse,
  AddInstructorRequest,
  RemoveInstructorRequest,
  CreateAssignmentBulkRequest,
  AddGraderRequest,
  RemoveGraderRequest,
  CreateClassBulkRequest,
  GetExternalClassesResponse,
  GetUserClassesRequest,
  GetUserClassesResponse
} from '@pi/api-types'

import Client from './client'

export default class Class {
  private client: Client

  constructor(client: Client) {
    this.client = client
  }

  async replaceInstructors(request: ReplaceInstructorsRequest): Promise<void> {
    await this.client.put({
      path: `/api/v3/classes/${request.id}/instructors`,
      body: JSON.stringify(request)
    })
  }

  async get({ classId }: GetClassRequest): Promise<GetClassResponse> {
    const { body } = await this.client.get<GetClassResponse>({
      path: `/api/v3/classes/${classId}`
    })
    return body
  }
  async search(request: SearchClassesRequest): Promise<SearchClassesResponse> {
    const params = new URLSearchParams({
      page: request.page ? request.page.toString() : '1',
      isArchived: request.isArchived ? request.isArchived.toString() : 'false',
      limit: request.limit ? request.limit.toString() : '1000'
    })
    const { body } = await this.client.get<SearchClassesResponse>({
      path: `/api/v3/classes?${params}`
    })
    return body
  }

  async getClassesForUser({
    id,
    ...params
  }: GetUserClassesRequest): Promise<GetUserClassesResponse> {
    const searchParams = new URLSearchParams()
    params.roles?.forEach(role => {
      searchParams.append('roles[]', role)
    })
    if (typeof params.includeArchived === 'boolean') {
      searchParams.set('includeArchived', params.includeArchived.toString())
    }
    const { body } = await this.client.get<GetUserClassesResponse>({
      path: `/api/v3/users/${id}/classes?${searchParams}`
    })
    return body
  }

  async getExternal(): Promise<GetExternalClassesResponse> {
    const { body } = await this.client.get<GetExternalClassesResponse>({
      path: '/api/v3/external-roster/classes'
    })
    return body
  }

  async create(request: CreateClassRequest): Promise<void> {
    const params = JSON.stringify(request)

    await this.client.post({
      path: `/api/v3/classes`,
      body: params
    })
  }

  async createBulk(request: CreateClassBulkRequest): Promise<void> {
    const params = JSON.stringify(request)

    await this.client.post({
      path: `/api/v3/classes/bulk`,
      body: params
    })
  }

  async update(request: UpdateClassRequest): Promise<void> {
    const params = JSON.stringify({ ...request, id: undefined })
    await this.client.put({
      path: `/api/v3/classes/${request.id}`,
      body: params
    })
  }

  async validate(
    request: ValidateJoinClassRequest
  ): Promise<ValidateJoinClassResponse> {
    const params = JSON.stringify(request)

    const { body } = await this.client.post<ValidateJoinClassResponse>({
      path: `/api/v3/classes/validate`,
      body: params
    })

    return body
  }

  async order(request: OrderClassSeatRequest): Promise<OrderClassSeatResponse> {
    const params = JSON.stringify(request)
    const { body } = await this.client.post<OrderClassSeatResponse>({
      path: `/api/v3/classes/order`,
      body: params
    })
    return body
  }

  async pay(request: CompleteClassSeatPurchaseRequest): Promise<void> {
    const params = JSON.stringify(request)
    const { body } = await this.client.post<void>({
      path: `/api/v3/classes/pay`,
      body: params
    })

    return body
  }

  async join(request: JoinClassRequest): Promise<void> {
    const params = JSON.stringify(request)

    await this.client.post({
      path: `/api/v3/classes/join`,
      body: params
    })
  }

  async archive(request: ArchiveClassRequest): Promise<void> {
    await this.client.post({
      path: `/api/v3/classes/${request.classId}/archive`
    })
  }

  async unarchive(request: UnarchiveClassRequest): Promise<void> {
    await this.client.post({
      path: `/api/v3/classes/${request.classId}/unarchive`
    })
  }

  async downloadGradesAsCSV(request: UnarchiveClassRequest): Promise<string> {
    const { body } = await this.client.get<string>({
      path: `/api/v3/classes/${request.classId}/grades.csv`
    })
    return body
  }

  async dropStudents(request: DropStudentsFromRosterRequest): Promise<void> {
    const params = JSON.stringify(request)
    await this.client.post({
      path: `/api/v3/classes/${request.classId}/students/drop`,
      body: params
    })
  }

  async restoreStudents(
    request: RestoreStudentsToRosterRequest
  ): Promise<void> {
    const params = JSON.stringify(request)
    await this.client.post({
      path: `/api/v3/classes/${request.classId}/students/restore`,
      body: params
    })
  }

  async transferStudents(
    request: TransferStudentsToClassRequest
  ): Promise<void> {
    const params = JSON.stringify(request)
    await this.client.post({
      path: `/api/v3/classes/${request.sourceClassId}/students/transfer`,
      body: params
    })
  }

  async getStudentsCompletedAssignments(
    request: StudentsCompletedAssignmentsRequest
  ): Promise<StudentsCompletedAssignmentsResponse> {
    const { body } =
      await this.client.get<StudentsCompletedAssignmentsResponse>({
        path: `/api/v3/classes/${request.classId}/students/${request.studentId}/assignments`
      })
    return body
  }

  async getAssignments(
    request: GetClassAssignmentsRequest
  ): Promise<unknown[]> {
    const queryParams = new URLSearchParams()
    if (request.sort) {
      queryParams.append('sort', request.sort)
    }
    if (request.dir) {
      queryParams.append('dir', request.dir)
    }

    const path = `/api/v3/classes/${
      request.classId
    }/assignments?${queryParams.toString()}`

    const { body } = await this.client.get<unknown[]>({
      path
    })

    return body
  }

  async addAssignment(request: CreateAssignmentRequest): Promise<string> {
    const params = JSON.stringify(request)
    const body = await this.client.post<any>({
      path: `/api/v3/classes/${request.classId}/assign`,
      body: params
    })

    const location = body.headers.get('location')
    const assignmentId = location?.replace('/api/v3/assignments/', '')
    return assignmentId ?? ''
  }

  //NEED TO UPDATE TO V3 ROUTE
  async addGrader(request: AddGraderRequest) {
    const params = JSON.stringify({ ...request, classId: undefined })
    await this.client.post({
      path: `/api/v2/classes/${request.classId}/graders`,
      body: params
    })
  }

  //NEED TO UPDATE TO V3 ROUTE
  async removeGrader(request: RemoveGraderRequest) {
    const params = JSON.stringify(request)
    await this.client.delete({
      path: `/api/v2/classes/${request.classId}/graders/${request.graderId}`,
      body: params
    })
  }

  async getInstructors(request: GetClassInstructorsRequest) {
    const { body } = await this.client.get<GetClassInstructorsResponse[]>({
      path: `/api/v3/classes/${request.classId}/instructors`
    })
    return body
  }

  async addInstructor(request: AddInstructorRequest) {
    const params = JSON.stringify({ ...request, classId: undefined })
    await this.client.post({
      path: `/api/v3/classes/${request.classId}/instructors`,
      body: params
    })
  }

  async removeInstructor(request: RemoveInstructorRequest) {
    const params = JSON.stringify(request)
    await this.client.delete({
      path: `/api/v3/classes/${request.classId}/instructors/${request.instructorId}`,
      body: params
    })
  }

  async addAssignments(request: CreateAssignmentBulkRequest): Promise<void> {
    const params = JSON.stringify(request)
    await this.client.post({
      path: `/api/v3/classes/assign/bulk/${request.classId}`,
      body: params
    })
  }
}
